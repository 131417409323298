<i18n src="@/locales/laboratory.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="clinic-laboratory">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <p>
          {{
            $t('article_p1')
          }}
        </p>
      </ImanArticle>
    </section>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video" />
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>
          {{ $t('h3_title_1') }}
        </ImanH3>
        <p>
          {{
            $t('article_p2')
          }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection>
        <ImanH3>
          {{ $t('h3_title_2') }}
        </ImanH3>
        <p>
          {{
            $t('article_p3')
          }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[1]">
        <ImanH3>
          {{ $t('h3_title_3') }}
        </ImanH3>
        <p>
          {{
            $t('article_p4')
          }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[2]">
        <ImanH3>
          {{ $t('h3_title_4') }}
        </ImanH3>
        <p>
          {{
            $t('article_p5')
          }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[3]">
        <ImanH3>
          {{ $t('h3_title_5') }}
        </ImanH3>
        <p>
          {{
            $t('article_p6')
          }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
import ImanMoreInfo from "@/components/ImanMoreInfo";
import ImanArticleSection from "@/components/ImanArticleSection";
import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: 'Laboratory',
  components: {
    ImanMoreInfo,
    ImanArticleSection
  },
  mixins: [
    articlesCardContentFilterMixin,
    metaMixin
  ],
  data() {
    return {
      articlesCardContentId: [83, 221, 52, 67],
      mainBackgroundClass: 'none-background',
      img: [
        {
          path: 'clinic/laboratory/scanner-intra-oral-dentaire.jpg',
          position: 'right',
          alt: 'scanner dentaire intra-oral implant dentaire'
        },
        {
          path: 'clinic/laboratory/EXO-CAD.jpg',
          position: 'right',
          alt: 'conception des prothèses et couronnes par ordinateur'
        },
        {
          path: 'clinic/laboratory/cad-cam-couronne.jpg',
          position: 'right',
          alt: 'CAD CAM fabrication des prothèses par un robot'
        },
        {
          path: 'clinic/laboratory/emax-press-ceramic.jpg',
          position: 'right',
          alt: 'Facettes et couronnes emax press ceramique'
        }
      ],
      video: {
        src: 'https://www.youtube.com/embed/STne24WFvK4'
      }
    }
  },
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_4.6.png')
    }
  },
  created() {
    window.addEventListener('scroll', this.whenScroll, {passive: true})
  },
  destroyed() {
    window.removeEventListener('scroll', this.whenScroll)
  },
  methods: {
    whenScroll() {
      if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
        this.mainBackgroundClass = 'none-background'
        this.countryOverlayClass = {
          overlay: 'overlay',
          element: 'element'
        }
      }
      if (document.body.scrollTop > 970 || document.documentElement.scrollTop > 970) {
        this.mainBackgroundClass = 'fixed-background'
        this.countryOverlayClass = {
          overlay: 'overlay',
          element: 'element'
        }
      }
      if (document.body.scrollTop > 2800 || document.documentElement.scrollTop > 2800) {
        this.countryOverlayClass = {
          overlay: 'overlay show-overlay',
          element: 'element show-element'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-background {
  @media only screen and (min-width: 768px) {
    /* tablet et ordinateurs */
    background-image: url("../../../assets/img/dental-implant/basal-implant/operation/H1-operation-implant-basal-d.jpeg");
  }
  @media only screen and (max-width: 767px) {
    /* smartphone en mode paysage */
    background-image: url("../../../assets/img/dental-implant/basal-implant/operation/H1-operation-implant-basal-t.jpg");
  }
  @media only screen and (max-width: 767px) and (orientation: portrait) {
    /* smartphone en mode portait */
    background-image: url("../../../assets/img/dental-implant/basal-implant/operation/H1-operation-implant-basal-m.jpg");
  }
}
</style>
